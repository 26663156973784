import React, { useContext, useEffect } from 'react';
import { PageProps, Link, graphql } from 'gatsby';
import SEO from '@/components/seo';
import CDNReplace from '@/components/CDNReplace';
import UILabel from '@/components/UI/UILabel';
import hoverCircle from '@/components/Interaction/hoverCircle';
import UIBreadcrumb from '@/components/UI/UIBreadcrumb';
import { Context } from '@/components/Layout';

import * as pageHeaderCategory from '@/styles/object/page-header-category.module.scss';

const CasesIndex: React.FC<PageProps<GatsbyTypes.CasesIndexQuery>> = (
  props,
) => {
  const { data } = props;
  const loading = useContext(Context);
  useEffect(() => {
    hoverCircle();
  }, []);

  return (
    !loading && (
      <>
        <SEO title="事例" />
        <div className="page-wrap --works">
          <div className="page-wrap__wave" />
          <div className="page-wrap__bg" />
          <header className={pageHeaderCategory.page_header_category}>
            <h1>
              <small
                className={pageHeaderCategory.page_header_category__head_text}
              >
                顧客事例
              </small>
              <div className={pageHeaderCategory.page_header_category__text}>
                Case Study
              </div>
            </h1>
            <p className={pageHeaderCategory.page_header_category__lead}>
              NRIネットコムが携わった顧客事例・お客さまの声をご紹介します。
            </p>
          </header>
          <div className="page-contents-box">
            <section className="section">
              <div className="box-3rows-02 --tab-rows">
                {data.allMicrocmsCase.edges.map(({ node }) => (
                  <Link
                    key={node.caseId}
                    to={`/cases/${node.caseId}/`}
                    className="box-3rows-02__item item-card-02 js-hover-circle"
                    data-circle-type=""
                    data-circle-inner="arrow-right"
                  >
                    <figure className="item-card-02__fig">
                      <img
                        loading="lazy"
                        src={`${CDNReplace(
                          node.thumbnail
                            ? node.thumbnail.url
                            : data.microcmsEnv.commonNoImage.url,
                        )}?fit=crop&w=320&h=180&auto=format&q=75`}
                        alt=""
                      />
                    </figure>
                    <div className="item-card-02__main">
                      <div className="item-card-02__tag">
                        {node.tag.map(({ tag, id }) => (
                          <UILabel key={id} text={tag} />
                        ))}
                      </div>
                      <h4 className="item-card-02__title">{node.title}</h4>
                      <p className="item-card-02__text">{node.customer}</p>
                    </div>
                  </Link>
                ))}
              </div>
            </section>
          </div>
        </div>
        <UIBreadcrumb UpperItem={[[`Works`, `/works`]]} CurrentItem="Case" />
      </>
    )
  );
};

export default CasesIndex;

export const query = graphql`
  query CasesIndex {
    allMicrocmsCase(
      sort: { fields: publishedAt, order: DESC }
      filter: { caseId: { ne: "template" } }
    ) {
      edges {
        node {
          caseId
          category
          customer
          title
          thumbnail {
            url
          }
          tag {
            tag
            id
          }
          publishedAt
        }
      }
    }
    microcmsEnv {
      commonNoImage {
        url
      }
    }
  }
`;
